import config from '@/config';
import { post as basePost } from '../lowLevel';
import { SetupAwsResponse } from './types';
import { getJwt } from '@/services/auth';

export const postSetupAwsAuth = async (
  identityId: string
): Promise<boolean> => {
  const response = (await (post('setupAws', {
    identityId,
  }) as unknown)) as SetupAwsResponse;

  return response.result === 'attached';
};

const post = async (
  path: string,
  body: any,
  passCognitoJwt = true
): Promise<any> => {
  return basePost(
    config.services.authServiceHosts,
    path,
    body,
    await getHeaders(passCognitoJwt)
  );
};

const getHeaders = async (cognito = true) => {
  let headers = {};

  if (cognito) {
    headers = {
      ...headers,
      Authorization: `Bearer ${await getJwt()}`,
    };
  }

  return headers;
};
