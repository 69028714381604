import { isSignedIn } from '@/services/auth';
import { startCloudWatchTimer } from '@/services/logger/cloudwatch';
import { useStore } from '@/store';

export const startServices = async () => {
  const store = useStore();

  if (await isSignedIn()) {
    store.setupAppData();
  }
  startCloudWatchTimer();
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const stopServices = async () => {};
