import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  concat,
  QueryOptions,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';

import config from '@/config';
import { getJwt } from '@/services/auth';
import logger from '@/services/logger';

const httpLink = new HttpLink({ uri: config.services.freedomQlHosts[0] });

const authMiddleware = setContext(async (_operation) => {
  const token = await getJwt();
  return {
    headers: {
      authorization: `Bearer ${token}`,
    },
  };
});

export const client = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache: new InMemoryCache(),
});

export const graphQlQuery = async (queryName: string, query: QueryOptions) => {
  try {
    const { data, error } = await client.query(query);

    if (error) {
      logger.error(`freedomQL: ${queryName}`, error);
      throw new Error(error.message);
    }

    logger.debug(`freedomQL: ${queryName}`, data);

    return data;
  } catch (err: any) {
    logger.error(`freedomQL: ${queryName} Exception`, err);
  }
};
