import baseEN from './en/base.json';

import authEN from './en/auth.json';

import formEN from './en/form.json';

import toastEN from './en/toast.json';
import devEN from './en/dev.json';

const messages = {
  en: {
    auth: authEN,
    base: baseEN,
    form: formEN,
    toast: toastEN,
    dev: devEN,
  },
};

export default {
  messages,
};
