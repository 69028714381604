import { useStore } from '@/store';
import { graphql } from './__generated__/gql';
import { graphQlQuery } from './client';
import {
  DocumentNode,
  OperationVariables,
  TypedDocumentNode,
} from '@apollo/client';
import {
  Account,
  AccountExtraRef,
  AddressWithType,
  Contact,
  ContactCard,
  Good,
  IntFavourite,
  Job,
  WebAccess,
} from './__generated__/graphql';
import { getGoodsEmailDesc } from '@/utils/helpers';
import { AddressTypes } from '@/config/constants';

const baseQuery = async (
  queryName: string,
  query: DocumentNode | TypedDocumentNode<any, OperationVariables>,
  variables?: OperationVariables | undefined
) => {
  return graphQlQuery(queryName, {
    query,
    fetchPolicy: 'no-cache',
    variables,
  });
};

const GET_APP_DATA = graphql(`
  #graphql
  query getAppData($codes: [String!]) {
    parameters(codes: $codes) {
      code
      setting
    }
  }
`);

export const getAppData = async (codes: string[]): Promise<any> => {
  return await baseQuery('getAppData', GET_APP_DATA, {
    codes,
  });
};

const GET_BASIC_CONTACT_DETAILS = graphql(`
  #graphql
  query getUserProfile($reference: Int!) {
    contact(reference: $reference) {
      reference
      accountRef
      contactName
      email
      mobile
      telephone
    }
  }
`);

export const getBasicContactDetails = async (
  reference = useStore().integraProfile.user.contactRef
): Promise<Contact> => {
  return (
    await baseQuery('getBasicContactDetails', GET_BASIC_CONTACT_DETAILS, {
      reference,
    })
  )?.contact;
};

const GET_ACCOUNT_DETAILS = graphql(`
  #graphql
  query getAccountDetails($reference: String!) {
    account(reference: $reference) {
      reference
      name
    }
  }
`);

export const getAccountDetails = async (
  reference = useStore().integraProfile.user.accountRef
): Promise<Account> => {
  return (
    await baseQuery('getAccountDetails', GET_ACCOUNT_DETAILS, {
      reference,
    })
  )?.account;
};

const GET_UNIVERSAL_ADDRESSES_PARTIAL = graphql(`
  #graphql
  query getUniversalAddressesPartial(
    $reference: Int!
    $partial: String
    $take: Int
    $types: [String!]
  ) {
    contact(reference: $reference) {
      contactName
      universalAddresses(partial: $partial, take: $take, types: $types) {
        addressName
        address1
        address2
        address3
        address4
        postcode
        country
        countryCode
        lat
        long
        reference
        accountRef
        contactRef
        notes
        code
        searchAddress
        typeCode
      }
    }
  }
`);

export const getUniversalAddressesPartial = async (
  reference: number,
  partial: string,
  types: string[],
  take?: number
): Promise<AddressWithType[]> => {
  types.sort((at: string) => {
    if (at === AddressTypes.CONTACT) {
      return -2;
    } else if (at === AddressTypes.ACCOUNT) {
      return -1;
    }
    return 0;
  });

  return (
    await baseQuery(
      'getUniversalAddressesPartial',
      GET_UNIVERSAL_ADDRESSES_PARTIAL,
      {
        reference,
        partial,
        types,
        take,
      }
    )
  ).contact?.universalAddresses;
};

const GET_DEFAULT_ADDRESSES = graphql(`
  #graphql
  query getDefaultAddresses($contactReference: Int!, $reference: String!) {
    account(reference: $reference) {
      defaultAddressRef
    }
    contact(reference: $contactReference) {
      defaultAddressRef
    }
  }
`);

export const getDefaultAddresses = async (
  contactReference = useStore().integraProfile.user.contactRef
): Promise<any> => {
  return await baseQuery('getDefaultAddresses', GET_DEFAULT_ADDRESSES, {
    reference: useStore().integraProfile.user.accountRef,
    contactReference,
  });
};

const GET_ADDRESS_FROM_REF = graphql(`
  #graphql
  query Address($reference: Int!) {
    address(reference: $reference) {
      addressName
      address1
      address2
      address3
      address4
      postcode
      country
      countryCode
      lat
      long
      reference
    }
  }
`);

export const getAddressFromRef = async (
  reference: number
): Promise<AddressWithType> => {
  return (
    await baseQuery('getAddressFromRef', GET_ADDRESS_FROM_REF, {
      reference: reference,
    })
  )?.address;
};

const GET_ACCOUNT_EXTRA_REFS = graphql(`
  #graphql
  query getAccountExtraRefs($reference: String!) {
    account(reference: $reference) {
      reference
      extraRefs {
        allowDefaultValue
        allowedFormats
        compulsory
        defaultAccountDeptRef
        description
        disabled
        filterByGroups
        maxLength
        prompt
        reference
        sortOrder
        validated
        webSearchDdl
        reference
        calculatedDefault
      }
    }
  }
`);

export const getAccountExtraRefs = async (
  reference = useStore().integraProfile.user.accountRef
): Promise<AccountExtraRef[]> => {
  return (
    await baseQuery('getAccountExtraRefs', GET_ACCOUNT_EXTRA_REFS, {
      reference,
    })
  )?.account?.extraRefs;
};

const GET_CONTACT_EXTRA_REFS = graphql(`
  #graphql
  query getContactExtraRefs($reference: Int!) {
    contact(reference: $reference) {
      reference
      extraRefs {
        allowDefaultValue
        allowedFormats
        compulsory
        defaultAccountDeptRef
        description
        disabled
        filterByGroups
        maxLength
        prompt
        reference
        sortOrder
        validated
        webSearchDdl
        reference
        calculatedDefault(reference: $reference)
      }
    }
  }
`);

export const getContactExtraRefs = async (
  reference = useStore().integraProfile.user.contactRef
): Promise<AccountExtraRef[]> => {
  return (
    await baseQuery('getContactExtraRefs', GET_CONTACT_EXTRA_REFS, {
      reference,
    })
  )?.contact?.extraRefs;
};

const GET_EXTRA_REF_VALUES = graphql(`
  #graphql
  query getExtraRefValues(
    $reference: Int!
    $accountExtraRefRef: Int!
    $partial: String
  ) {
    contact(reference: $reference) {
      extraRefValues(
        accountExtraRefRef: $accountExtraRefRef
        partial: $partial
      ) {
        code
        description
        reference
      }
    }
  }
`);

export const getExtraRefValues = async (
  accountExtraRefRef: number,
  partial?: string
): Promise<any> => {
  const store = useStore();
  return (
    await baseQuery('getExtraRefValues', GET_EXTRA_REF_VALUES, {
      reference: store.integraProfile.user.contactRef,
      accountExtraRefRef,
      partial,
    })
  )?.contact?.extraRefValues;
};

const GET_JOB_DETAILS = graphql(`
  #graphql
  query getJobDetails($reference: Int!, $take: Int) {
    job(reference: $reference) {
      reference
      tariff
      planGoods
      pickupDt
      pickupDate
      dateEntered
      enteredBy
      status
      formattedEstPriceTotal
      webInstructions
      contactRef
      contactName
      contactPhone
      contactMobile
      contactEmail
      stops {
        reference
        dropOrder
        addressName
        address1
        address2
        address3
        address4
        postcode
        country
        countryCode
        lat
        long
      }
      goods {
        type
        info3
      }
      flags {
        description
        sortOrder
      }
      tariffDetail {
        description
      }
      webTariff {
        label
        description
        appImgUrl
      }
      driver {
        firstname
        surname
        telMobile
        oLicenceNumber
        photo {
          picHeight
          picWidth
          dataUri
        }
      }
      vehicle {
        registration
        vehicleClass
        vehicleColour
        description
      }
      tracking(take: $take) {
        locationDt
        gridY
        gridX
        locationDir
        locationSpeed
        locationDesc
        timezoneOffsetMins
        locationDateTimeUtc
      }
      oagFlightJob {
        reference
        jobRef
        arrival
        stopRef
        flightDt
        fromToCity
        flightCode
        statusEta
        departAirportCode
        arriveAirportCode
        holdOff
      }
    }
  }
`);

export const getJobDetails = async (
  reference: number,
  take?: number
): Promise<any> => {
  return (
    await baseQuery('getJobDetails', GET_JOB_DETAILS, {
      reference,
      take,
    })
  )?.job;
};

const GET_CONTACT_CARD_DETAILS = graphql(`
  #graphql
  query getContactCardDetails($reference: Int!) {
    contact(reference: $reference) {
      defaultContactCardRef
      contactCards {
        reference
        lastFourCharacters
        cardTypeCode
        validFrom
        validTo
        nameOnCard
      }
    }
  }
`);

export const getContactCardDetails = async (
  reference = useStore().integraProfile.user.contactRef
): Promise<{
  contactCards: ContactCard[];
  defaultContactCardRef: number;
}> => {
  return (
    await baseQuery('getContactCards', GET_CONTACT_CARD_DETAILS, {
      reference,
    })
  )?.contact;
};

export const getContactAddresses = async (
  reference = useStore().integraProfile.user.contactRef
): Promise<{
  addresses: AddressWithType[];
  defaultContactAddress?: AddressWithType;
  defaultAccountAddress?: AddressWithType;
}> => {
  const addresses = await getUniversalAddressesPartial(reference, '', [
    AddressTypes.CONTACT,
    AddressTypes.ACCOUNT,
    AddressTypes.RECENT,
  ]);
  const defaults = await getDefaultAddresses(reference);

  return {
    addresses,
    defaultContactAddress: addresses.find(
      (address) => address.reference == defaults.contact.defaultAddressRef
    ),
    defaultAccountAddress: addresses.find(
      (address) => address.reference == defaults.account.defaultAddressRef
    ),
  };
};

const GET_JOB_DETAILS_FOR_EDIT = graphql(`
  #graphql
  query getJobDetailsForEdit($reference: Int!) {
    job(reference: $reference) {
      reference
      tariff
      pickupDt
      waitReturn
      webInstructions
      webInstructionsMaxLength
      mop
      goodsQty
      contactRef
      contactName
      contactPhone
      contactMobile
      contactEmail
      invoiced
      holdReason
      dateCancelled
      stops {
        reference
        dropOrder
        addressName
        address1
        address2
        address3
        address4
        postcode
        country
        countryCode
        lat
        long
      }
      goods {
        type
        description
        code
        code2
        info1
        info2
        info3
        unitWeight
        unitHeight
        unitWidth
        unitDepth
        unitValue
        unitCartons
        nonDoc
      }
      flags {
        flagId
      }
      accountDept
      otherRef
      jobExtraRefs {
        referenceValue
        accountExtraRefRef
      }
      oagFlightJob {
        stopRef
        flightCode
        fromToCity
        scheduledArrivalDt
        terminal
        arrival
        flightNo
        carrierCode
        scheduledDepartureDt
        scheduledArrivalDt
        departAirportCode
        arriveAirportCode
        holdOff
      }
    }
  }
`);

export const getJobDetailsForEdit = async (reference: number): Promise<Job> => {
  return (
    await baseQuery('getJobDetailsForEdit', GET_JOB_DETAILS_FOR_EDIT, {
      reference,
    })
  )?.job;
};

const GET_FAVOURITE_JOURNEYS = graphql(`
  #graphql
  query FavouriteJourneys($reference: Int!, $take: Int) {
    contact(reference: $reference) {
      favouriteJourneys(take: $take) {
        reference
        tariff
        waitReturn
        webInstructions
        webInstructionsMaxLength
        mop
        goodsQty
        contactRef
        contactName
        contactPhone
        contactMobile
        contactEmail
        webTariff {
          goodsType
        }
        stops {
          reference
          dropOrder
          addressName
          address1
          address2
          address3
          address4
          postcode
          country
          countryCode
          lat
          long
        }
        goods {
          type
          description
          code
          code2
          info1
          info2
          info3
          unitWeight
          unitHeight
          unitWidth
          unitDepth
          unitValue
          unitCartons
          nonDoc
        }
        flags {
          flagId
        }
        accountDept
        otherRef
        jobExtraRefs {
          referenceValue
          accountExtraRefRef
        }
        oagFlightJob {
          stopRef
          flightCode
          fromToCity
          scheduledArrivalDt
          terminal
          arrival
          flightNo
          carrierCode
          scheduledDepartureDt
          scheduledArrivalDt
          departAirportCode
          arriveAirportCode
          holdOff
        }
      }
    }
  }
`);

export const getFavouriteJourneys = async (take?: number): Promise<Job[]> => {
  return (
    await baseQuery('getFavouriteJourneys', GET_FAVOURITE_JOURNEYS, {
      reference: useStore().integraProfile.user.contactRef,
      take,
    })
  )?.contact?.favouriteJourneys;
};

const GET_CONTACT_FAVOURITE_JOURNEYS = graphql(`
  #graphql
  query getContactFavouriteJourneys($reference: Int!, $take: Int) {
    contact(reference: $reference) {
      intJobFavourites(take: $take) {
        reference
        jobRef
        description
        job {
          reference
          tariff
          waitReturn
          webInstructions
          webInstructionsMaxLength
          mop
          goodsQty
          contactRef
          contactName
          contactPhone
          contactMobile
          contactEmail
          webTariff {
            goodsType
          }
          stops {
            reference
            dropOrder
            addressName
            address1
            address2
            address3
            address4
            postcode
            country
            countryCode
            lat
            long
          }
          goods {
            type
            description
            code
            code2
            info1
            info2
            info3
            unitWeight
            unitHeight
            unitWidth
            unitDepth
            unitValue
            unitCartons
            nonDoc
          }
          flags {
            flagId
          }
          accountDept
          otherRef
          jobExtraRefs {
            referenceValue
            accountExtraRefRef
          }
          oagFlightJob {
            stopRef
            flightCode
            fromToCity
            scheduledArrivalDt
            terminal
            arrival
            flightNo
            carrierCode
            scheduledDepartureDt
            scheduledArrivalDt
            departAirportCode
            arriveAirportCode
            holdOff
          }
        }
      }
    }
  }
`);

export const getContactFavouriteJourneys = async (
  take?: number
): Promise<IntFavourite[]> => {
  return (
    await baseQuery(
      'getContactFavouriteJourneys',
      GET_CONTACT_FAVOURITE_JOURNEYS,
      {
        reference: useStore().integraProfile.user.contactRef,
        take,
      }
    )
  )?.contact?.intJobFavourites;
};

const GET_CONTACT_MASQUERADE_COUNTS = graphql(`
  #graphql
  query getContactMasqueradeCounts($reference: Int!) {
    contact(reference: $reference) {
      bookForContactsCount
      masqueradeAndMaintainContactsCount
    }
  }
`);

export const getContactMasqueradeCounts = async (
  reference = useStore().integraProfile.user.contactRef
): Promise<{
  bookForContactsCount: number;
  masqueradeAndMaintainContactsCount: number;
}> => {
  return (
    await baseQuery(
      'getContactMasqueradeCounts',
      GET_CONTACT_MASQUERADE_COUNTS,
      {
        reference,
      }
    )
  )?.contact;
};

const GET_BOOK_FOR_CONTACTS = graphql(`
  #graphql
  query getBookForContacts($reference: Int!, $partial: String, $take: Int) {
    contact(reference: $reference) {
      bookForContacts(partial: $partial, take: $take) {
        reference
        accountRef
        contactName
        telephone
        mobile
        email
      }
    }
  }
`);

export const getBookForContacts = async (
  partial: string,
  reference = useStore().integraProfile.user.contactRef,
  take?: number
): Promise<Contact[]> => {
  return (
    await baseQuery('getBookForContacts', GET_BOOK_FOR_CONTACTS, {
      reference,
      partial,
      take,
    })
  )?.contact?.bookForContacts;
};

const GET_MASQUERADE_AND_MAINTAIN_CONTACTS = graphql(`
  #graphql
  query getMasqueradeAndMaintainContacts(
    $reference: Int!
    $partial: String
    $take: Int
  ) {
    contact(reference: $reference) {
      masqueradeAndMaintainContacts(partial: $partial, take: $take) {
        reference
        accountRef
        contactName
        telephone
        mobile
        email
      }
    }
  }
`);

export const getMasqueradeAndMaintainContacts = async (
  partial: string,
  reference = useStore().integraProfile.user.contactRef,
  take?: number
): Promise<Contact[]> => {
  return (
    await baseQuery(
      'getMasqueradeAndMaintainContacts',
      GET_MASQUERADE_AND_MAINTAIN_CONTACTS,
      {
        reference,
        partial,
        take,
      }
    )
  )?.contact?.masqueradeAndMaintainContacts;
};

const GET_CONTACT_WEB_ACCESS = graphql(`
  #graphql
  query getContactWebAccess($reference: Int!) {
    contact(reference: $reference) {
      webAccess {
        reference
        contactRef
        maintainContacts
        maintainChildContacts
        viewContacts
        editContacts
        disableContacts
        viewAddresses
        addAddresses
        editAddresses
        deleteAddresses
        viewCards
        addCards
        disableCards
        addJobs
        addJobsOthers
        editJobs
        editJobsOthers
        cancelJobs
        cancelJobsOthers
        canRequest
        maintainDepts
      }
    }
  }
`);

export const getContactWebAccess = async (
  reference = useStore().integraProfile.user.contactRef
): Promise<WebAccess> => {
  return (
    await baseQuery('getContactWebAccess', GET_CONTACT_WEB_ACCESS, {
      reference,
    })
  )?.contact?.webAccess;
};

const GET_CONTACT_CALCULATED_DEFAULTS = graphql(`
  #graphql
  query getContactCalculatedDefaults($reference: Int!) {
    contact(reference: $reference) {
      calculatedDefaultTariff
      calculatedDefaultDespInst
      calculatedDefaultJobFlags
      calculatedDefaultMop
    }
  }
`);

export const getContactCalculatedDefaults = async (
  reference = useStore().integraProfile.user.contactRef
): Promise<Contact> => {
  return (
    await baseQuery(
      'getContactCalculatedDefaults',
      GET_CONTACT_CALCULATED_DEFAULTS,
      {
        reference,
      }
    )
  )?.contact;
};

const GET_JOB_EMAILS = graphql(`
  #graphql
  query getJobEmails($reference: Int!) {
    job(reference: $reference) {
      reference
      contactEmail
      bookerEmail
      goods {
        info1
        info2
        info3
      }
    }
  }
`);

export const getJobEmails = async (reference: number): Promise<string[]> => {
  const profile = useStore().integraProfile;
  const job: Job = (
    await baseQuery('getJobEmails', GET_JOB_EMAILS, {
      reference,
    })
  )?.job;

  const emailKey = getGoodsEmailDesc();
  return [
    ...new Set([
      profile.user.bemail,
      ...(job.contactEmail ? [job.contactEmail] : []),
      ...(emailKey
        ? job.goods
            .map((good: Good) => good[emailKey])
            .filter((email): email is string => !!email)
        : []),
    ]),
  ];
};

const GET_JOB_ACCESS = graphql(`
  #graphql
  query getJobAccess($reference: Int!, $contactRef: Int!) {
    job(reference: $reference) {
      contactHasAccess(contactRef: $contactRef)
      isJobContact(contactRef: $contactRef)
    }
  }
`);

export const getJobAccess = async (
  reference: number,
  contactRef = useStore().integraProfile.user.contactRef
): Promise<{ contactHasAccess: boolean; isJobContact: boolean }> => {
  return (
    await baseQuery('getJobAccess', GET_JOB_ACCESS, {
      reference,
      contactRef,
    })
  )?.job;
};
