import { createApp } from 'vue';

import App from './App.vue';
import router from './router';

import { IonicVue, useBackButton } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables & global css */
import './theme/variables.css';
import './theme/global.css';

/* Swiper css */
import 'swiper/css';
import 'swiper/css/pagination';

import BaseLayout from './components/BaseLayout.vue';
import { getSessionFromCode, setupAuth } from './services/auth';
import logger, { updateLogLevel } from '@/services/logger';
import { setupLoggerDb } from './services/logger/store';
import { initialisePinia, useStore } from '@/store';
import { startServices } from './services/serviceControl';
import { SplashScreen } from '@capacitor/splash-screen';
import { i18n, t } from './utils/i18n';
import {
  displaySignOutAlert,
  displayExitAppAlert,
} from '@/services/alertService';
import { URLOpenListenerEvent } from '@capacitor/app';
import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import { UIToast } from './utils/UiToast';
import config from './config';

logger.info('env', process.env);

await setupAuth();

const pinia = initialisePinia();

const app = createApp(App)
  .use(IonicVue)
  .use(logger)
  .use(pinia)
  .use(i18n)
  .use(router);

app.component('base-layout', BaseLayout);

const onResume = async () => {
  await useStore().$hydrate();
  const dynamicConfig = useStore().dynamicConfig;
  updateLogLevel(dynamicConfig.app.logLevel);

  logger.info('onResume');

  await setupLoggerDb();
  await startServices();
};

const onMenuButton = async () => {
  logger.debug('onMenuButton');
};

const onSearchButton = async () => {
  logger.debug('onSearchButton');
};

useBackButton(10, async () => {
  const route = router.currentRoute.value.path;
  if (route === '/signin') {
    await displayExitAppAlert();
  } else if (route === '/home') {
    await displaySignOutAlert();
  } else {
    router.back();
  }
});

const onReady = async () => {
  await useStore().$hydrate();
  useStore().activeBooking.isActive = false;
  const dynamicConfig = useStore().dynamicConfig;
  updateLogLevel(dynamicConfig.app.logLevel);

  logger.info('onReady');
  await setupLoggerDb();

  document.addEventListener('resume', onResume, false);
  document.addEventListener('menubutton', onMenuButton, false);
  document.addEventListener('searchbutton', onSearchButton, false);

  try {
    await startServices();
  } catch (error: any) {
    logger.error('initaliseServices', { error });
  } finally {
    await SplashScreen.hide({ fadeOutDuration: 500 });
  }
};

document.addEventListener('visibilitychange', async () => {
  const visibility = document.visibilityState;
  logger.info(`visibilitychange - ${visibility}`);
  // fires when user switches tabs, apps, goes to homescreen, etc.
  if (visibility === 'hidden') {
    await useStore().$persist();
  }

  // fires when app transitions from prerender, user returns to the app / tab.
  if (visibility === 'visible') {
    await useStore().$hydrate();
  }
});

window.onunhandledrejection = (event) => {
  logger.error('Unhandled rejection', { event });
};

// listen for App Callback Urls
if (
  config.services.aws.awsConfig.Auth?.Cognito.loginWith &&
  Capacitor.isNativePlatform()
) {
  CapacitorApp.addListener(
    'appUrlOpen',
    async (event: URLOpenListenerEvent) => {
      logger.debug('appUrlOpen', event);
      try {
        await Browser.close();
        // Example url: capacitor://com.catalinasoftware.freedom.gox/sso?code=****&state=****
        const url = event.url;
        if (url && url.startsWith('capacitor://')) {
          const error = new URL(event.url)?.searchParams.get(
            'error_description'
          );
          const code = new URL(event.url)?.searchParams.get('code');

          if (error) {
            throw new Error(decodeURIComponent(error));
          } else if (code) {
            await getSessionFromCode(code);
            router.push({ name: 'sso' });
          }
        }
      } catch (e: any) {
        logger.error('appUrlOpen', e.message ?? e);
        await UIToast.displayCloseableWarningToastWithDuration(
          t('base.errorOccured'),
          'top'
        );
      }
    }
  );
}

router
  .isReady()
  .then(() => {
    app.mount('#app');
  })
  .then(async () => {
    onReady();
  });
