import axios, {
  AxiosHeaders,
  AxiosRequestConfig,
  AxiosResponseHeaders,
  Method,
  RawAxiosRequestHeaders,
  RawAxiosResponseHeaders,
} from 'axios';

import logger from '@/services/logger';
import config from '@/config';
import { getPlatform } from '@/services/plugins/device';

const REQUEST_FAILED = 'Request failed with status code ';
const SERVICE_ERRORS = [
  'Network Error',
  `${REQUEST_FAILED}401`,
  `${REQUEST_FAILED}500`,
  `${REQUEST_FAILED}501`,
  `${REQUEST_FAILED}502`,
  `${REQUEST_FAILED}503`,
  `${REQUEST_FAILED}504`,
  `${REQUEST_FAILED}505`,
  `${REQUEST_FAILED}506`,
  `${REQUEST_FAILED}507`,
  `${REQUEST_FAILED}508`,
  `${REQUEST_FAILED}510`,
  `${REQUEST_FAILED}511`,
];

export type Response = {
  headers: RawAxiosResponseHeaders | AxiosResponseHeaders;
  data: unknown;
};

export type RequestHeaders =
  | (RawAxiosRequestHeaders &
      Partial<
        {
          [Key in Method as Lowercase<Key>]: AxiosHeaders;
        } & { common: AxiosHeaders }
      >)
  | AxiosHeaders;

export const sendDelete = async (
  url: string,
  additionalHeaders = {}
): Promise<Response> => {
  try {
    logger.debug('DELETE', { url });
    const { headers, data } = await axios.delete(
      url,
      await getConfig(additionalHeaders)
    );
    logger.debug('DELETE response', data);
    return { headers, data };
  } catch (error: any) {
    logger.error('DELETE', { url, error });
    throw error;
  }
};

export const get = async (
  baseUrls: string[],
  path: string,
  allowed404 = false,
  additionalHeaders = {}
): Promise<Response> => {
  const options = await getConfig(additionalHeaders);
  if (allowed404) {
    options.validateStatus = (status: number) =>
      [200, 201, 202, 204, 404].includes(status);
  }

  for (const baseUrl of baseUrls) {
    const url = baseUrl + path;
    try {
      logger.debug('GET', { url });
      const { headers, data } = await axios.get(url, options);
      logger.debug('GET response', data);
      return { headers, data };
    } catch (error: any) {
      if (SERVICE_ERRORS.includes(error.message)) {
        logger.warn('GET service error', { url, error });
        continue;
      } else {
        logger.error('GET application error', { url, error });
        throw error;
      }
    }
  }

  logger.error('GET No servers responded', { baseUrls, path });
  throw new Error(`GET ${path}, No servers responded: ${baseUrls}`);
};

export const post = async (
  baseUrls: string[],
  path: string,
  requestData: unknown,
  additionalHeaders = {}
): Promise<Response> => {
  for (const baseUrl of baseUrls) {
    const url = baseUrl + path;
    try {
      logger.debug('POST', { url, requestData });
      const { headers, data } = await axios.post(
        url,
        requestData,
        await getConfig(additionalHeaders)
      );
      logger.debug('POST response', data);
      return { headers, data };
    } catch (error: any) {
      if (SERVICE_ERRORS.includes(error.message)) {
        logger.warn('POST service error', { url, error });
        continue;
      } else {
        logger.error('POST application error', { url, error });
        throw error;
      }
    }
  }

  logger.error('POST No servers responded', { baseUrls, path });
  throw new Error(`POST ${path}, No servers responded: ${baseUrls}`);
};

export const put = async (
  baseUrls: string[],
  path: string,
  requestData: unknown,
  additionalHeaders = {}
): Promise<Response> => {
  for (const baseUrl of baseUrls) {
    const url = baseUrl + path;
    try {
      logger.debug('PUT', { url, requestData });
      const { headers, data } = await axios.put(
        url,
        requestData,
        await getConfig(additionalHeaders)
      );
      logger.debug('PUT response', data);
      return { headers, data };
    } catch (error: any) {
      if (SERVICE_ERRORS.includes(error.message)) {
        logger.warn('PUT service error', { url, error });
        continue;
      } else {
        logger.error('PUT application error', { url, error });
        throw error;
      }
    }
  }

  logger.error('PUT No servers responded', { baseUrls, path });
  throw new Error(`PUT ${path}, No servers responded: ${baseUrls}`);
};

export const uploadFile = async (
  baseUrls: string[],
  path: string,
  file: File,
  filename: string,
  additionalHeaders = {}
): Promise<Response> => {
  const formData = new FormData();
  formData.append('file', file, filename);

  for (const baseUrl of baseUrls) {
    const url = baseUrl + path;
    try {
      logger.debug('UPLOADFILE', { url, formData });
      const { headers, data } = await axios.post(
        url,
        formData,
        await getConfig(additionalHeaders)
      );
      logger.debug('UPLOADFILE response', data);
      return { headers, data };
    } catch (error: any) {
      if (SERVICE_ERRORS.includes(error.message)) {
        logger.warn('UPLOADFILE service error', { url, formData, error });
        continue;
      } else {
        logger.error('UPLOADFILE application error', { url, formData, error });
        throw error;
      }
    }
  }

  logger.error('UPLOADFILE No servers responded', { baseUrls, path });
  throw new Error(`UPLOADFILE ${path}, No servers responded: ${baseUrls}`);
};

const getConfig = async (
  additionalHeaders: RequestHeaders
): Promise<AxiosRequestConfig> => {
  const platform = await getPlatform();
  const headers = {
    ...additionalHeaders,
    'Content-Type': 'application/json',
    'integra-app': `FreedomGoX,${
      platform === 'web' || platform === 'ios' ? 'iOS' : 'Android'
    }`,
  };

  return { timeout: config.services.apiMsTimeout, headers };
};
