import { CapacitorConfig } from '@capacitor/cli';

const config: CapacitorConfig = {
  appId: 'com.catalinasoftware.freedom.goxscs',
  appName: 'SCS: Chauffeur Services',
  webDir: 'dist',
  ios: {
    appendUserAgent: 'ios:application',
    webContentsDebuggingEnabled: true,
  },
  server: {
    hostname: 'com.catalinasoftware.freedom.goxscs',
  },
  plugins: {
    SplashScreen: {
      launchAutoHide: false,
      launchFadeOutDuration: 500,
      showSpinner: false,
    },
  },
};

export default config;
