import { Device, DeviceInfo } from '@capacitor/device';

export const getDevice = async (): Promise<DeviceInfo> => {
  return await Device.getInfo();
};

export const getPlatform = async (): Promise<string> =>
  (await getDevice()).platform;

export const getModelName = async (): Promise<string> =>
  (await getDevice()).name ?? 'Unknown';

export const isBrowser = async (): Promise<boolean> =>
  (await getDevice()).platform === 'web';

export const isAndroid = async (): Promise<boolean> =>
  (await getDevice()).platform === 'android';

export const isIOs = async (): Promise<boolean> =>
  (await getDevice()).platform === 'ios';

export enum NavigationApp {
  NAV_NATIVE,
  NAV_WAZE,
}

export const openNavigateApp = async (
  app: NavigationApp,
  navTo: string
): Promise<void> => {
  const parts = navTo.split('/');
  const address = parts[0].split(',');
  const latlong = parts[1];

  if (app === NavigationApp.NAV_NATIVE) {
    switch ((await getDevice()).platform) {
      case 'ios':
        window.open(
          `http://maps.apple.com/?daddr=${latlong}&dirflg=d`,
          '_system'
        );
        break;
      case 'android':
        window.open(`geo:0,0?q=${parts[1]}(${address[0]})`, '_system');
        break;
      default:
        window.open(`https://maps.google.com/maps?daddr=${latlong}&amp;ll=`);
        break;
    }
  }

  if (app === NavigationApp.NAV_WAZE) {
    if (latlong) {
      window.open(`https://waze.com/ul?ll=${latlong}&navigate=yes`);
    } else {
      window.open(`https://waze.com/ul?q=${address}&navigate=yes`);
    }
  }
};
