import { Amplify } from 'aws-amplify';
import { ConsoleLogger } from 'aws-amplify/utils';
import {
  cognitoUserPoolsTokenProvider,
  confirmUserAttribute,
  sendUserAttributeVerificationCode,
  SignInOutput,
} from 'aws-amplify/auth/cognito';

import {
  confirmResetPassword,
  confirmSignUp as awsConfirmSignUp,
  signIn as awsSignIn,
  confirmSignIn as awsConfirmSignIn,
  signOut as awsSignOut,
  signUp as awsSignUp,
  deleteUser as awsDeleteUser,
  fetchAuthSession,
  getCurrentUser,
  resetPassword,
  fetchUserAttributes,
  updatePassword,
  updateUserAttributes,
  SignUpOutput,
  signInWithRedirect,
} from 'aws-amplify/auth';

import { postSetupAwsAuth } from '@/api/authService/service';
import router from '@/router';
import { useStore } from '@/store';
import logger from '../logger';
import { startServices, stopServices } from '../serviceControl';
import { postLogOn, postSignOut } from '@/api/integraLink/service';
import { AuthStorage } from './store';
import config from '@/config';
import {
  Account,
  IntegraProfile,
  IntegraSignInInfo,
  isErrorResponse,
} from '@/api/integraLink/types';
import { populateAppDetailsFromSignIn } from '../appDetails';
import { integraUserToContact } from '@/utils/helpers';
import { Capacitor } from '@capacitor/core';
import { awsConfig, getSsoUrl } from '@/config/aws';
import { Browser } from '@capacitor/browser';
import axios from 'axios';
import { jwtDecode, JwtPayload } from 'jwt-decode';

export type UserAttributes = {
  userId: string;
  name: string;
  email: string;
  mobile?: string;
  federatedId: string;
  telephone?: string;
  mfaLastDate?: string;
  identities?: string;
};

export const setupAuth = async () => {
  try {
    ConsoleLogger.LOG_LEVEL =
      process.env.NODE_ENV === 'development' ? 'DEBUG' : 'ERROR';
    Amplify.configure(config.services.aws.awsConfig);
    const authStorage = new AuthStorage();
    await authStorage.sync();
    cognitoUserPoolsTokenProvider.setKeyValueStorage(authStorage);
  } catch (error) {
    logger.error('setupAuth', { error });
  }
};

export const isSignedIn = async (): Promise<boolean> => {
  try {
    const result = await getCurrentUser();
    logger.debug('isSignedIn', { result });

    return true;
  } catch (error: any) {
    // if they were logged in, we need to log them out if their session has been expired
    const isUserSignedIn = useStore().isSignedIntoIntegra;
    if (isUserSignedIn && error.name === 'UserUnAuthenticatedException') {
      await handleAutoSignOut();
    }

    if (error.name === 'UserUnAuthenticatedException') {
      logger.debug('isSignedIn', { error });
    } else {
      logger.error('isSignedIn', { error });
    }

    return false;
  }
};

export const getUsername = async (): Promise<string> => {
  try {
    const { username } = await getCurrentUser();
    return username;
  } catch (e: any) {
    logger.error('getUsername', e.message ?? e);
    throw e;
  }
};

export const isSso = async (): Promise<boolean> => {
  try {
    const { identities } = await getUserAttributes();

    logger.debug('isSso', { identities });

    return !!identities;
  } catch (e: any) {
    logger.error('isSso', e.message ?? e);
    throw e;
  }
};

export const getJwt = async (
  forceRefresh = false
): Promise<string | undefined> => {
  try {
    const { tokens } = await fetchAuthSession({ forceRefresh });
    if (!tokens) {
      logger.error('getJwt: No tokens');
      throw new Error('No id JWT');
    }

    const { idToken } = tokens;

    if (!idToken) {
      logger.error('getJwt: No id JWT');
      throw new Error('No id JWT');
    }

    return idToken.toString();
  } catch (err: any) {
    logger.error('getJwt', err.message ?? err);

    // if they were logged in, we need to log them out if their session has been expired
    const isUserSignedIn = useStore().isSignedIntoIntegra;
    if (isUserSignedIn && err.name === 'UserUnAuthenticatedException') {
      await handleAutoSignOut();
    }
  }
};

export const getUserAttributes = async (): Promise<UserAttributes> => {
  try {
    const attributes = await fetchUserAttributes();

    return {
      userId: attributes['custom:user_id'] || '',
      name: attributes['name'] || '',
      email: attributes['email'] || '',
      mobile: attributes['phone_number'] || '',
      federatedId: attributes['custom:federated_id'] || '',
      telephone: attributes['custom:telephone'] || '',
      mfaLastDate: attributes['custom:mfa_last_date'] || '',
      identities: attributes['identities'] || '',
    };
  } catch (e: any) {
    logger.error('getUserAttributes', e.message ?? e);
    throw e;
  }
};

export const updateUserAttribute = async (
  name: string,
  value: string
): Promise<void> => {
  const userAttributes: Record<string, string> = {};
  userAttributes[name] = value;

  try {
    const result = await updateUserAttributes({ userAttributes });
    logger.debug('updateUserAttribute', { result });
  } catch (error) {
    logger.error('updateUserAttribute', error);
  }
};

export const signUp = async (
  email: string,
  password: string,
  name: string,
  mobile: string
): Promise<SignUpOutput> => {
  try {
    const result = await awsSignUp({
      username: email,
      password,

      options: {
        userAttributes: {
          phone_number: mobile,
          name,
        },
      },
    });
    const store = useStore();
    store.signInDetails.signInEmail = email;
    logger.info('signUp', { result });
    return result;
  } catch (e: any) {
    logger.error('signUp', e.message ?? e);
    throw e;
  }
};

export const confirmSignUp = async (
  username: string,
  confirmationCode: string
): Promise<any> => {
  try {
    const result = await awsConfirmSignUp({ username, confirmationCode });
    logger.info('confirmSignUp', { result });
    return result;
  } catch (e: any) {
    logger.error('confirmSignUp', e.message ?? e);
    throw e;
  }
};

const resetMfa = () => {
  const store = useStore();
  store.mfaDetails.wrongPassCount = 0;
  store.mfaDetails.pending = false;
  store.mfaDetails.legacyPending = false;
  store.mfaDetails.email = '';
};

export const ssoSignIn = async (): Promise<void> => {
  try {
    if (await isSignedIn()) {
      await signOut();
    }

    logger.debug('ssoSignIn');
    const ips = JSON.parse(
      process.env.VUE_APP_AUTH_USER_POOL_IDENTITY_PROVIDERS || '[]'
    );
    logger.debug('identityProviders', ips);
    const ip = ips.length === 1 ? ips[0] : 'COGNITO';
    logger.debug('identityProvider', ip);

    if (Capacitor.isNativePlatform()) {
      const url = getSsoUrl(ip);
      Browser.open({ url });
    } else {
      await signInWithRedirect({ provider: { custom: ip } });
    }
  } catch (e: any) {
    logger.error('ssoSignIn', e.message ?? e);
    throw e;
  }
};

export const signIn = async (
  email: string,
  password: string,
  remember: boolean
): Promise<void> => {
  const store = useStore();

  try {
    store.resetStore(false); // we normally do this on SignOut, but that may have failed if we failed to SignOut fully

    if (store.mfaDetails.email !== email) {
      resetMfa();
    }

    const signInRes = await awsSignIn({
      username: email,
      password,
      options: {
        clientMetadata: {
          wrongPassCount: store.mfaDetails.wrongPassCount.toString(),
        },
      },
    });

    logger.debug('signInRes', { signInRes });
    await handleSignIn(email, remember, signInRes);
  } catch (e: any) {
    let throwError = true;
    let retryRes;
    switch (e.name) {
      // UserNotFoundException: User does not exist (new user pool)
      // TODO - Remove when all users are moved over to new user pools
      case 'UserNotFoundException':
        retryRes = await awsSignIn({
          username: email,
          password,
          options: {
            authFlowType: 'USER_PASSWORD_AUTH',
            clientMetadata: {
              wrongPassCount: store.mfaDetails.wrongPassCount.toString(),
            },
          },
        });

        logger.debug('retryRes', { retryRes });
        await handleSignIn(email, remember, retryRes);
        throwError = false;
        break;

      // NotAuthorizedException: Incorrect username or password. (wrong password)
      case 'NotAuthorizedException':
        store.mfaDetails.email = email;
        store.incrementWrongPassCount();
        break;

      default:
        break;
    }

    if (throwError) {
      logger.error('signIn', e.message ?? e);
      throw e;
    }
  }
};

const handleSignIn = async (
  email: string,
  remember: boolean,
  signInRes: SignInOutput
) => {
  const store = useStore();
  store.signInDetails = {
    ...store.signInDetails,
    signInEmail: email,
    remember,
  };

  switch (signInRes.nextStep.signInStep) {
    case 'DONE':
      if (store.mfaDetails.wrongPassCount > 2) {
        await legacyHandleMfa(signInRes);
      } else {
        await continueSignIn();
      }

      break;

    case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
    case 'RESET_PASSWORD':
      logger.debug(signInRes.nextStep.signInStep, { signInRes });
      if (
        signInRes.nextStep.signInStep ===
        'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED'
      ) {
        store.signInDetails.confirmSignInPending = true;
      }
      router.push({
        name: 'setPassword',
        params: {
          initialEmail: email,
        },
      });

      break;

    default:
      logger.debug('MFA initiated', { signInRes });
      store.mfaDetails.pending = true;
      break;
  }
};

// TODO - Remove when https://catalina-software.atlassian.net/browse/GOX-482 deployed
const legacyHandleMfa = async (signInRes: SignInOutput) => {
  await sendUserAttributeVerificationCode({
    userAttributeKey: 'phone_number',
  });

  useStore().mfaDetails.legacyPending = true;
  logger.debug('Legacy MFA initiated', { signInRes });
};

export const confirmSignIn = async (challengeResponse: string) => {
  try {
    await awsConfirmSignIn({ challengeResponse });

    logger.debug('signInConfirmed', { challengeResponse });
    await continueSignIn();
  } catch (e: any) {
    await awsSignOut();
    logger.error('confirmSignIn', e.message ?? e);
    throw e;
  }
};

// TODO - Remove when https://catalina-software.atlassian.net/browse/GOX-482 deployed
export const legacyConfirmSignIn = async (confirmationCode: string) => {
  try {
    await confirmUserAttribute({
      userAttributeKey: 'phone_number',
      confirmationCode,
    });

    logger.debug('legacySignInConfirmed', { confirmationCode });
    await continueSignIn();
  } catch (e: any) {
    await awsSignOut();
    logger.error('legacyConfirmSignIn', e.message ?? e);
    throw e;
  }
};

export const continueSignIn = async () => {
  resetMfa();
  await setupAccessToAwsForUser();
  await handleIntegraLogOn();
};

const handleIntegraLogOn = async (): Promise<void> => {
  try {
    const { email } = await getUserAttributes();
    const logOnRes = await postLogOn({
      email,
    });
    if (isErrorResponse(logOnRes)) {
      throw new Error(logOnRes.error.text);
    }
    if (logOnRes.error) {
      throw new Error(logOnRes.error.message);
    } else {
      const integraProfile = logOnRes.profile;
      if (integraProfile) {
        useStore().integraProfile = integraProfile;
        if (integraProfile.loggedIn) {
          await completeSignIn(logOnRes.profile);
        } else {
          await router.push({ name: 'selectProfile' });
        }
      }
    }
  } catch (e: any) {
    logger.error('handleIntegraSignIn', e.message ?? e);
    throw e;
  }
};

export const completeSignIn = async (
  profile: IntegraProfile,
  accountSignUpRef?: string
) => {
  handleIntegraProfile(profile, accountSignUpRef);
  await populateAppDetailsFromSignIn();
  await startServices();
  useStore().mfaDetails.wrongPassCount = 0;
  await router.push({ name: 'home' });
};

const handleIntegraProfile = (
  profile: IntegraProfile,
  accountSignUpRef?: string
) => {
  const store = useStore();
  let accounts: Account[] = store.integraProfile.accounts;
  if (accountSignUpRef) {
    // Only update "profile" of account that is being signed up to
    const accountSignUp = profile.accounts.find(
      (a) => a.accountRef === accountSignUpRef
    );

    if (accountSignUp) {
      const filteredAccounts = accounts.filter(
        (account) => account.accountRef !== accountSignUpRef
      );

      accounts = [...filteredAccounts, accountSignUp];
    }
  }

  // Only update "profiles" on initial signIn call
  store.integraProfile = { ...profile, accounts };

  // Set profile and job contacts
  store.profileContact = store.jobContact = integraUserToContact(profile.user);
};

const setupAccessToAwsForUser = async (): Promise<void> => {
  try {
    const { federatedId } = await getUserAttributes();

    if (!federatedId) {
      // This is the credentials that PubSub uses to connect (from the identity pool, not the user pool)
      // So we will call our Lambda to add the policy, via API Gateway
      const { identityId } = await fetchAuthSession();

      if (identityId) {
        const success = await postSetupAwsAuth(identityId);
        if (success) {
          updateUserAttribute('custom:federated_id', identityId);
        }
      }
    }
  } catch (e: any) {
    logger.error('setupAccessToAwsForUser', e.message ?? e);
    throw e;
  }
};

export const signOutCognito = async () => {
  try {
    await awsSignOut({ global: true });
  } catch (e: any) {
    logger.error('signOutCognito', e.message ?? e);
    throw e;
  } finally {
    useStore().resetStore();
  }
};

export const signOut = async (): Promise<void> => {
  try {
    if (!(await isSso()) || Capacitor.isNativePlatform()) {
      await router.replace({ name: 'signin' });
    }

    await stopServices();
    await signOutFromIntegra();
    await signOutCognito();
  } catch (e: any) {
    logger.error('signOut', e.message ?? e);
    throw e;
  }
};

export const signOutFromIntegra = async (): Promise<void> => {
  try {
    const signOutRes = await postSignOut();
    if (isErrorResponse(signOutRes)) {
      throw new Error(signOutRes.error.text);
    }
  } catch (e: any) {
    // Even if integra SignOut fails, we still want to log out of everything else
    logger.warn('signOutFromIntegra', e.message ?? e);
  }
};

export const handleAutoSignOut = async (): Promise<void> => {
  logger.info('handleAutoSignOut');
  try {
    await stopServices();
    await awsSignOut();
    router.replace({ name: 'signin' });
  } catch (e: any) {
    logger.error('handleAutoSignOut', e.message ?? e);
    throw e;
  } finally {
    useStore().resetStore();
  }
};

export const forgotPassword = async (username: string): Promise<any> => {
  try {
    const result = await resetPassword({ username });
    logger.info('forgotPasword', { result });
    return result;
  } catch (e: any) {
    logger.error('forgotPassword', e.message ?? e);
    throw e;
  }
};

export const confirmForgotPassword = async (
  username: string,
  confirmationCode: string,
  newPassword: string
): Promise<void> => {
  try {
    await confirmResetPassword({ username, confirmationCode, newPassword });
    logger.info('forgotPasswordSubmit', true);
  } catch (e: any) {
    logger.error('confirmForgotPassword', e.message ?? e);
    throw e;
  }
};

export const deleteUser = async (): Promise<void> => {
  try {
    await awsDeleteUser();
    logger.info('deleteUser', true);
  } catch (e: any) {
    logger.error('deleteUser', e.message ?? e);
    throw e;
  } finally {
    useStore().resetStore();
  }
};

export const changePassword = async (
  oldPassword: string,
  newPassword: string
): Promise<any> => {
  try {
    const result = await updatePassword({ oldPassword, newPassword });
    logger.info('changePassword', { result });
    return result;
  } catch (e: any) {
    logger.error('changePassword', e.message ?? e);
    throw e;
  }
};

export const integraLogOn = async (
  body: IntegraSignInInfo,
  accountSignUpRef?: string
) => {
  const { name, mobile } = await getUserAttributes();
  body = { ...body, name, mobile };
  const logOnRes = await postLogOn(body);

  if (isErrorResponse(logOnRes)) {
    throw new Error(logOnRes.error.text);
  }
  if (logOnRes.error) {
    throw new Error(logOnRes.error.message);
  }

  logger.info('integraLogOn', logOnRes);
  await completeSignIn(logOnRes.profile, accountSignUpRef);
};

export const getSessionFromCode = async (code: string): Promise<void> => {
  try {
    const cognitoConfig = awsConfig.Auth?.Cognito;
    logger.debug('getSessionFromCode', { cognitoConfig });

    if (!cognitoConfig) {
      throw new Error('Cognito configuration is missing.');
    }

    const { loginWith, userPoolClientId } = cognitoConfig;
    if (
      !loginWith?.oauth?.domain ||
      !userPoolClientId ||
      !loginWith?.oauth?.redirectSignIn?.[0]
    ) {
      throw new Error('Cognito OAuth configuration is incomplete.');
    }

    const tokenUrl = `https://${loginWith.oauth.domain}/oauth2/token`;
    const urlEncodedData = new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: userPoolClientId,
      code,
      redirect_uri: loginWith.oauth.redirectSignIn[0],
    }).toString();

    const tokens = await getTokens(tokenUrl, urlEncodedData);
    logger.debug('getSessionFromCode tokens', { tokens });

    if (!tokens.access_token) {
      throw new Error('Access token is missing.');
    }

    const { clockDrift, username } = getSessionDetails(
      tokens.access_token,
      tokens.id_token
    );

    if (
      username &&
      tokens.id_token &&
      tokens.access_token &&
      tokens.refresh_token
    ) {
      const preKey = `CognitoIdentityServiceProvider.${userPoolClientId}.`;
      const keyValueStorage =
        cognitoUserPoolsTokenProvider.authTokenStore.keyValueStorage;

      keyValueStorage?.setItem(`${preKey}LastAuthUser`, username);
      const key = `${preKey}${username}.`;
      keyValueStorage?.setItem(`${key}idToken`, tokens.id_token);
      keyValueStorage?.setItem(`${key}accessToken`, tokens.access_token);
      keyValueStorage?.setItem(`${key}refreshToken`, tokens.refresh_token);
      keyValueStorage?.setItem(`${key}clockDrift`, clockDrift ?? '0');
      logger.debug('getSessionFromCode values set');
    } else {
      throw new Error('Missing values for session.');
    }
  } catch (e: any) {
    logger.error('getSessionFromCode', e.message ?? e);
    throw e;
  }
};

const getTokens = async (url: string, urlEncodedData: string) => {
  const response = await axios.post(url, urlEncodedData, {
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  });

  return response.data;
};

const decodeToken = (token: string): JwtPayload => {
  return jwtDecode<JwtPayload>(token);
};

const getSessionDetails = (
  accessToken: string,
  idToken: string
): { clockDrift?: string; username?: string } => {
  let clockDrift;
  const now = Math.floor(Date.now() / 1000);
  const decodedAccess = decodeToken(accessToken);
  const decodedId = decodeToken(idToken);

  const accessTokenIat = decodedAccess.iat;
  const idTokenIat = decodedId.iat;

  if (accessTokenIat && idTokenIat) {
    clockDrift = (now - Math.min(accessTokenIat, idTokenIat)).toString();
  }

  return { clockDrift, username: decodedAccess.sub };
};

export const refreshIntegraSession = async (
  email: string,
  contactRef: number,
  accountRef: string
) => {
  try {
    logger.info('refreshIntegraSession');
    const logOnRes = await postLogOn({
      email,
      contactRef,
      accountRef,
    });

    if (isErrorResponse(logOnRes)) {
      throw new Error(logOnRes.error.text);
    }
    if (logOnRes.error) {
      throw new Error(logOnRes.error.message);
    }

    logger.debug('logOnRes', { logOnRes });
    handleIntegraProfile(logOnRes.profile, accountRef);
  } catch (e: any) {
    logger.error('refreshIntegraSession', e.message ?? e);
    throw e;
  }
};
