import { getBookingFlow } from '@/api/integraLink/service';
import { useStore } from '@/store';
import logger from './logger';
import config from '@/config';
import { isErrorResponse } from '@/api/integraLink/types';
import {
  getBookForContacts,
  getContactMasqueradeCounts,
  getContactWebAccess,
  getMasqueradeAndMaintainContacts,
} from '@/api/freedomQl';
import { AppDetails } from '@/store/state';
import { WebAccess } from '@/api/freedomQl/__generated__/graphql';

export const populateAppDetailsFromSignIn = async () => {
  try {
    const newAppDetails = await populateAppDetails();
    logger.info('appDetailsPopulated', newAppDetails);
  } catch (e: any) {
    logger.error('populateAppDetailsFromSignIn', e.message ?? e);
    throw e;
  }
};

export const checkRefreshAppDetails = async () => {
  const store = useStore();
  const prevUpdated = store.appDetails.updated;
  const now = new Date();
  if (prevUpdated) {
    const minsSinceUpdate = Math.floor(
      (now.valueOf() - new Date(prevUpdated).valueOf()) / 60000
    );

    if (minsSinceUpdate < config.app.appDetailsRefreshMins) {
      return;
    }
  }

  await refreshAppDetails();
};

export const refreshAppDetails = async () => {
  try {
    const newAppDetails = await populateAppDetails();
    logger.info('appDetailsRefreshed', newAppDetails);
  } catch (e: any) {
    logger.error('refreshAppDetails', e.message ?? e);
    throw e;
  }
};

const populateAppDetails = async () => {
  const store = useStore();
  // call synchronously
  store.setBackgroundLocation();
  const bookingFlowRes = await getBookingFlow();
  if (isErrorResponse(bookingFlowRes)) {
    throw new Error(bookingFlowRes.error.text);
  }
  const webAccess = await getContactWebAccess();

  const { bookFor, masqueradeAndMaintain } = await handleMasqueradeContacts(
    webAccess
  );

  const appDetails: AppDetails = {
    updated: new Date().toISOString(),
    bookingFlow: bookingFlowRes,
    webAccess,
    bookFor,
    masqueradeAndMaintain,
  };

  store.appDetails = appDetails;

  return appDetails;
};

const handleMasqueradeContacts = async (webAccess: WebAccess) => {
  const { bookForContactsCount, masqueradeAndMaintainContactsCount } =
    await getContactMasqueradeCounts();
  const bookForSearch = bookForContactsCount > config.app.maxContactDropDown;
  const masqueradeAndMaintainSearch =
    masqueradeAndMaintainContactsCount > config.app.maxContactDropDown;

  const bookFor = {
    count: bookForContactsCount,
    useSearch: bookForSearch,
    contacts: bookForSearch ? [] : await getBookForContacts(''),
  };

  const masqueradeAndMaintain =
    masqueradeAndMaintainContactsCount > 0 &&
    (webAccess.addJobsOthers === 'YES' ||
      webAccess.maintainContacts === 'YES' ||
      webAccess.maintainChildContacts === 'YES' ||
      webAccess.viewContacts === 'YES')
      ? {
          count: masqueradeAndMaintainContactsCount,
          useSearch: masqueradeAndMaintainSearch,
          contacts: masqueradeAndMaintainSearch
            ? []
            : await getMasqueradeAndMaintainContacts(''),
        }
      : undefined;

  return { bookFor, masqueradeAndMaintain };
};
