import {
  Duration,
  format,
  formatDistanceToNow,
  isValid,
  parse,
  parseISO,
  sub,
  formatRelative as dateFnsFormatRelative,
  addMinutes,
  differenceInMinutes,
  set,
} from 'date-fns';
import { enGB } from 'date-fns/locale';

const formatRelativeLocale: Record<string, any> = {
  lastWeek: "'Last' eeee 'at' p aaa",
  yesterday: "'Yesterday at' p aaa",
  today: "'Today at' p aaa",
  tomorrow: "'Tomorrow at' p aaa",
  nextWeek: "eeee 'at' p aaa",
  other: "P 'at' p aaa",
};

const locale = {
  ...enGB,
  formatRelative: (token: string) => formatRelativeLocale[token],
};

const nowUnixTimestamp = (): number => Math.round(new Date().getTime() / 1000);

const nowSubtract = (duration: Duration): Date => {
  return sub(new Date(), duration);
};

const dateParse = (value: string, format = 'dd/MM/yyyy'): Date => {
  return parse(value, format, new Date());
};

const dateParseISO = (value: string): Date => {
  return parseISO(value);
};

const formatDate = (value: Date): string => {
  return format(value, 'dd/MM/yyyy');
};

const formatTime = (value: Date, includeSeconds = false): string => {
  if (includeSeconds) {
    return format(value, 'HH:mm:ss.SSS');
  }
  return format(value, 'HH:mm');
};

const formatDateTime = (value: Date, includeSeconds = false): string => {
  if (includeSeconds) {
    return format(value, 'dd/MM/yyyy HH:mm:ss.SSS');
  }
  return format(value, 'dd/MM/yyyy HH:mm');
};

const formatDateTimeToIso = (value: Date): string => {
  return format(value, "yyyy-MM-dd'T'HH:mm:ss.SSS");
};

const formatLogDate = (value: Date): string => {
  return format(value, 'yyyyMMdd');
};

const formatRelative = (value: Date): string => {
  return dateFnsFormatRelative(value, new Date(), { locale });
};

const datePickerValueFromString = (value: string | Date[]): Date[] => {
  if (Array.isArray(value)) {
    return value;
  }
  if (value === '') {
    return [];
  }

  const result = dateParse(value);

  return isValid(result) ? [result] : [];
};

const datePickerValueToString = (value: string | Date[]): string => {
  if (typeof value === 'string') {
    return value;
  }
  if (value.length === 0) {
    return '';
  }
  return formatDate(value[0]);
};

const dayMonthYearToString = (dd: string, mm: string, yyyy: string): string => {
  return formatDate(dateParse(`${dd}/${mm}/${yyyy}`));
};

const formatIsoString = (iso: string): string => {
  return formatDateTime(dateParseISO(iso));
};

const hoursBetweenDates = (d1: Date, d2: Date): number => {
  return Math.abs(d1.getTime() - d2.getTime()) / 3600000;
};

const getDateParts = (value: Date) => {
  const dateString = format(value, 'dd,MM,yy,HH,mm,ss');
  const dateParts = dateString.split(',');
  return {
    day: dateParts[0],
    month: dateParts[1],
    year: dateParts[2],
    hour: dateParts[3],
    minute: dateParts[4],
    second: dateParts[5],
  };
};

const toMinute = (value: Date) => set(value, { seconds: 0, milliseconds: 0 });

export default {
  nowUnixTimestamp,
  nowSubtract,
  dateParse,
  dateParseISO,
  datePickerValueFromString,
  datePickerValueToString,
  formatDate,
  formatTime,
  formatDateTime,
  formatDateTimeToIso,
  formatDistanceToNow,
  formatLogDate,
  formatRelative,
  dayMonthYearToString,
  formatIsoString,
  hoursBetweenDates,
  getDateParts,
  addMinutes,
  differenceInMinutes,
  toMinute,
};
