import { Contact, Good } from '@/api/freedomQl/__generated__/graphql';
import { IntegraUser } from '@/api/integraLink/types';
import { paymentTypes } from '@/config/constants';
import { isBrowser } from '@/services/plugins/device';
import { useStore } from '@/store';
import { Keyboard } from '@capacitor/keyboard';
import {
  personCircleOutline,
  cardOutline,
  cashOutline,
  walletOutline,
} from 'ionicons/icons';

export const isWebx = () => {
  return window.innerWidth > 992;
};

export const arrayEquals = (a: any[], b: any) => {
  return JSON.stringify(a) == JSON.stringify(b);
};

export const metersToMiles = (meters: number): number =>
  meters * 0.000621371192;

export const metersToKilometers = (meters: number): number => meters / 1000;

export const getPaymentTypeIcon = (paymentChar: string) => {
  switch (paymentChar) {
    case paymentTypes.account:
      return personCircleOutline;

    case paymentTypes.card:
      return cardOutline;

    case paymentTypes.cash:
      return cashOutline;

    default:
      return walletOutline;
  }
};

export const integraUserToContact = (user: IntegraUser): Contact => {
  return {
    reference: user.contactRef.toString(),
    accountRef: user.accountRef,
    contactName: user.bname,
    telephone: user.btel,
    mobile: user.bmob,
    email: user.bemail,
  } as Contact;
};

export const handleDialCodeMobile = (dialCode: string, mobile: string) => {
  if (dialCode === '+44' && mobile.charAt(0) === '0') {
    mobile = mobile.slice(1);
  }

  return (dialCode + mobile).replace(/\s/g, '');
};

export const scrollInView = (id: string) => {
  document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
};

export const toNum = (s?: string | number) => {
  return s ? +s : undefined;
};

export const enumIncludes = (enumeratedType: any, value: string) => {
  return Object.values(enumeratedType).includes(value);
};

export const getGoodsEmailDesc = (): keyof Good | undefined => {
  const settings = useStore().integraProfile.settings;
  for (const key in settings) {
    if (
      key.toLowerCase().startsWith('goods_') &&
      settings[key].toLowerCase().includes('mail')
    ) {
      return key
        .replace('goods_', '')
        .replace('_desc', '')
        .toLowerCase() as keyof Good;
    }
  }
};

export const getApiCountries = (apiCountries?: string) => {
  // 2 = all
  // 1 = non-UK only
  // 0 = UK only

  switch (apiCountries) {
    // TODO - handle 1 (non-UK only)
    case '1':
    case '2':
      return undefined;

    default:
      return 'GB';
  }
};

export const isMultipleOf = (val: number, multipleOf: number): boolean => {
  return val % multipleOf === 0;
};

export const hideKeyboard = async () => {
  if (!(await isBrowser())) {
    await Keyboard.hide();
  }
};
