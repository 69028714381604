import locale from '@/locale';
import { createI18n } from 'vue-i18n';

export const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: locale.messages,
  legacy: false,
  globalInjection: true,
});

export const t = i18n.global.t;

export const l = i18n.global.locale;
