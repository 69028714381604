import {
  PiniaPlugin,
  PiniaPluginContext,
  StateTree,
  Store,
  SubscriptionCallbackMutation,
} from 'pinia';
import localForage from 'localforage';

import logger from '@/services/logger';

declare module 'pinia' {
  export interface PiniaCustomProperties {
    /**
     * Rehydrates store from persisted state
     */
    $hydrate: () => Promise<void>;

    /**
     * Persists store into configured storage
     */
    $persist: () => Promise<void>;
  }
}

const hydrateState = async (store: Store) => {
  try {
    const stored = await localForage.getItem(store.$id + '-state');
    if (stored) {
      store.$patch(stored as object);
    }
  } catch (error) {
    logger.error('hydrateState', { error });
  }
};

const persistState = async (store: Store, state: StateTree) => {
  try {
    await localForage.setItem(
      store.$id + '-state',
      JSON.parse(JSON.stringify(state))
    );
  } catch (error) {
    logger.error('persistState', { error });
  }
};

export const createLocalForagePlugin = (): PiniaPlugin => {
  return (context: PiniaPluginContext) => {
    const { store } = context;

    store.$persist = async () => {
      await persistState(store, store.$state);
    };

    store.$hydrate = async () => {
      await hydrateState(store);
    };

    hydrateState(store);

    store.$subscribe(
      (
        _mutation: SubscriptionCallbackMutation<StateTree>,
        state: StateTree
      ) => {
        persistState(store, state);
      },
      {
        detached: true,
      }
    );
  };
};
