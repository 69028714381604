import config from '@/config';
import logger from '.';

class SectionLogger {
  constructor(section: string) {
    this.section = section;
  }

  private section: string;

  debug = (...args: any) => {
    if (config.devLogControl[this.section]) {
      const message = args.shift();
      if (args.length > 0) {
        return logger.debug(message, args);
      }
      logger.debug(message);
    }
  };

  info = (...args: any) => {
    const message = args.shift();
    if (args.length > 0) {
      return logger.info(message, args);
    }
    logger.info(message);
  };

  warn = (...args: any) => {
    const message = args.shift();
    if (args.length > 0) {
      return logger.warn(message, args);
    }
    logger.warn(message);
  };

  error = (...args: any) => {
    const message = args.shift();
    if (args.length > 0) {
      return logger.error(message, args);
    }
    logger.error(message);
  };
}

export const createSectionLogger = (section: string) => {
  return new SectionLogger(section);
};
