import { Buffer } from 'buffer';

export const flatten = (
  data: Record<string, unknown>,
  ignoreKeys: string[]
): Record<string, unknown> => {
  const result: any = {};
  function recurse(cur: any, prop: any) {
    if (ignoreKeys.includes(prop)) return;
    if (Object(cur) !== cur) {
      result[prop] = cur;
    } else if (Array.isArray(cur)) {
      const l = cur.length;
      for (let i = 0; i < l; i++) recurse(cur[i], prop + '[' + i + ']');
      if (l == 0) result[prop] = [];
    } else {
      let isEmpty = true;
      for (const p in cur) {
        isEmpty = false;
        recurse(cur[p], prop ? prop + '.' + p : p);
      }
      if (isEmpty && prop) result[prop] = {};
    }
  }
  recurse(data, '');
  return result;
};

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export function groupBy<K, V>(
  list: Array<V>,
  keyGetter: (input: V) => K
): Map<K, Array<V>> {
  const map = new Map<K, Array<V>>();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export const encodeBase64 = (string: string): string => {
  const b = Buffer.from(string);

  return b.toString('base64');
};

export const decodeBase64 = (base64: string): string => {
  const b = Buffer.from(base64, 'base64');

  return b.toString();
};

/**
 * @description
 * Takes an object,
 * loops through every key, if the value is a string it will trim the value,
 * if another object is found as the value then recursively call the function again.
 *
 * @param obj An object.
 *
 * @returns The same object with all of the string values trimmed.
 */
export const trimObjectValues = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'string') {
      obj[key] = obj[key].trim();
    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
      trimObjectValues(obj[key]);
    }
  });
  return obj;
};

export const removeFunctions = (obj: any) => {
  const clone = { ...obj };

  for (const key of Object.keys(obj)) {
    if (typeof clone[key] === 'function') {
      delete clone[key];
    }
  }

  return clone;
};
