import { toastController } from '@ionic/vue';
import { warningOutline } from 'ionicons/icons';

/**
 * Utility class for displaying popup toast notifications.
 * @class
 */

export class UIToast {
  /**
   * Create a toast with a close button and display until closed
   * @method displayCloseableToast
   * @public
   * @static
   * @param {string} message The toast message
   * @param {string} position The toast position
   */
  public static displayCloseableToast = async (
    message: string,
    position: 'top' | 'middle' | 'bottom'
  ): Promise<void> => {
    const toast = await toastController.create({
      message,
      position,
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel',
        },
      ],
    });

    await toast.present();
  };

  /**
   * Create a toast with a duration, no close button
   * @method displayToastWithDuration
   * @public
   * @static
   * @param {string} message The toast message
   * @param {string} position The toast position
   * @param {number} duration The toast duration
   */
  public static displayToastWithDuration = async (
    message: string,
    position: 'top' | 'middle' | 'bottom',
    duration = 3000
  ): Promise<void> => {
    const toast = await toastController.create({
      message,
      position,
      duration,
      color: 'success',
    });

    await toast.present();
  };

  /**
   * Create a toast with a close button, warning icon, and duration.
   * @method displayCloseableWarningToastWithDuration
   * @public
   * @static
   * @param {string} message The toast message
   * @param {string} position The toast position
   * @param {number} duration The toast display time in ms
   *
   */
  public static displayCloseableWarningToastWithDuration = async (
    message: string,
    position: 'top' | 'middle' | 'bottom',
    duration = 3000
  ): Promise<void> => {
    const toast = await toastController.create({
      message,
      position,
      icon: warningOutline,
      duration,
      color: 'danger',
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel',
        },
      ],
    });

    await toast.present();
  };
}
