import { createPinia, defineStore } from 'pinia';
import { PiniaLogger } from 'pinia-logger';

import { State, getDefaultState } from './state';
import { createLocalForagePlugin } from './persist';
import {
  BookingDelay,
  ErrorResponse,
  WebTariff,
} from '@/api/integraLink/types';
import { getAppData } from '@/api/freedomQl';
import { Geolocation } from '@capacitor/geolocation';
import logger from '@/services/logger';

export const initialisePinia = () => {
  // PiniaLogger Defaults are:
  // const defaultOptions = {
  //   logErrors: true,
  //   disabled: false,
  //   expanded: true,
  //   showStoreName: true,
  //   showDuration: false,
  //   showTime: true,
  //   filter: () => true
  //   actions: undefined
  // }

  return createPinia()
    .use(
      PiniaLogger({
        expanded: true,
        disabled: process.env.NODE_ENV !== 'development',
        // use a filter to only log certain actions
        //filter: ({ name }) => name !== 'incrementCounter',
        // alternatively, you can specify the actions you want to log
        // if undefined, all actions will be logged
        //actions: ['decrementCounter'],
      })
    )
    .use(createLocalForagePlugin());
};

export const useStore = defineStore('appStore', {
  state: (): State => getDefaultState(),
  getters: {
    isSignedIntoIntegra(state) {
      return state.integraProfile?.loggedIn === 1;
    },
    isJobMasquerading(state) {
      return (
        state.integraProfile.user.contactRef !== +state.jobContact.reference
      );
    },
    isProfileMasquerading(state) {
      return (
        state.integraProfile.user.contactRef !== +state.profileContact.reference
      );
    },
    getAvailableTariffs(state) {
      const booking = state.activeBooking;
      const filteredTariffs = booking?.tariffs?.reduce((result, t) => {
        if (booking?.bookingForm?.goodsQty <= t.max_goods_qty) {
          if (
            booking?.bookingForm?.goodsCarton === undefined ||
            t.max_goods_cartons === null ||
            booking?.bookingForm?.goodsCarton <= t.max_goods_cartons
          ) {
            result.push(t);
          }
        }
        return result;
      }, [] as WebTariff[]);

      return filteredTariffs?.sort((a, b) => a.sort_order - b.sort_order);
    },
    getDelayForCurrentTariff(state) {
      return state.activeBooking?.delays.find(
        (delay: ErrorResponse | BookingDelay) =>
          !('error' in delay) &&
          delay.tariff === state.activeBooking?.bookingForm.tariff
      ) as BookingDelay;
    },
    getParameter(state) {
      return (code: string) => {
        return (
          state.appData.parameters.find((parameter) => parameter.code === code)
            ?.setting ?? ''
        );
      };
    },
    getCancellationReasons(state) {
      const reasons =
        state.appData.parameters.find(
          (parameter) => parameter.code === 'INTEGRA_CANCREASONS'
        )?.setting ?? '';
      return reasons.split('\r');
    },
  },
  actions: {
    resetStore(clearMfa = true) {
      const signInDetails = this.signInDetails;
      this.signInDetails.confirmSignInPending = false;
      const mfaDetails = this.mfaDetails;
      const cookieConsent = this.cookieConsent;

      Object.assign(this, getDefaultState());
      this.signInDetails = signInDetails;
      this.cookieConsent = cookieConsent;

      if (clearMfa) {
        return;
      }

      this.mfaDetails = mfaDetails;
    },
    incrementWrongPassCount() {
      this.mfaDetails.wrongPassCount++;
    },
    resetMasqueradeSearchContacts() {
      if (this.appDetails.bookFor?.useSearch) {
        this.appDetails.bookFor.contacts = [];
      }

      if (this.appDetails.masqueradeAndMaintain?.useSearch) {
        this.appDetails.masqueradeAndMaintain.contacts = [];
      }
    },
    async setupAppData() {
      this.appData = await getAppData(['INTEGRA_CANCREASONS']);
    },
    async setBackgroundLocation() {
      try {
        const location = await Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
        });

        this.location = location;
        logger.debug('Set background location', { location });
      } catch (e: any) {
        logger.error('setBackgroundLocation', e.message ?? e);
      }
    },
    resetQuotes() {
      this.activeBooking.quotes = [];
      this.activeBooking.quoteError = undefined;
      this.activeBooking.journeyDistance = undefined;
      this.activeBooking.journeyDuration = undefined;
    },
  },
});
