import {
  getJwt,
  handleAutoSignOut,
  refreshIntegraSession,
} from '@/services/auth';
import {
  RequestHeaders,
  // sendDelete as baseDelete,
  get as baseGet,
  post as basePost,
  put as basePut,
  // uploadFile as baseUploadFile,
} from '../lowLevel';
import {
  IntegraContactInfo,
  IntegraSignInInfo,
  BookingFlow,
  Booking,
  BookingDelayBody,
  BookingQuoteBody,
  AdflexSessionResponse,
  AdflexSessionRequest,
  ErrorResponse,
  NewBookingResponse,
  BookingDelay,
  BookingQuoteResponse,
  ReportRunResponse,
  ContactCardAddResponse,
  ContactCardAddRequest,
  ContactCardDeleteRequest,
  ContactCardMakeDefaultRequest,
  SuccessResponse,
  CancelBookingRequest,
  CancelBookingResponse,
  ContactAddressAddResponse,
  ContactAddressAddRequest,
  ContactAddressEditRequest,
  ContactAddressEditResponse,
  ContactAddressDeleteRequest,
  ContactAddressDeleteResponse,
  EditBookingResponse,
  TariffErrorResponse,
  ContactAddressClearDefaultRequest,
  ContactAddressClearDefaultResponse,
  ContactAddressMakeDefaultRequest,
  ContactAddressMakeDefaultResponse,
  JourneyMakeFavouriteRequest,
  JourneyClearFavouriteRequest,
  JourneyClearFavouriteResponse,
  JourneyMakeFavouriteResponse,
  ReportListResponse,
  ReportRunRequest,
  LogOnResponse,
  FlightLookupResponse,
  FlightLookupRequest,
  ContactLinkReqRequest,
  ContactLinkReqResponse,
  ContactLinkListRequest,
  ContactLinkListResponse,
  ContactLinkPendingListResponse,
  ContactLinkAuthoriseRequest,
  ContactLinkAuthoriseResponse,
  SendConfirmationRequest,
  SendConfirmationResponse,
  Populate3waDetailsRequest,
  Populate3waDetailsResponse,
  SwitchToBrowserResponse,
} from './types';
import { useStore } from '@/store';
import logger from '@/services/logger';
import config from '@/config';

export const putContact = async (
  body: IntegraContactInfo
): Promise<ErrorResponse | IntegraContactInfo> => {
  return put('contact', body);
};

export const postLogOn = async (
  body: IntegraSignInInfo
): Promise<ErrorResponse | LogOnResponse> => {
  return post('session/logon', body, false, false);
};

export const postSignOut = async (): Promise<
  ErrorResponse | SuccessResponse
> => {
  return await post('auth/signOut', {});
};

export const postBooking = async (
  body: Booking
): Promise<ErrorResponse | NewBookingResponse> => {
  return await post('booking/new', body);
};

export const editBooking = async (
  body: Booking
): Promise<ErrorResponse | EditBookingResponse> => {
  return await post('booking/edit', body);
};

export const postCancelBooking = async (
  body: CancelBookingRequest
): Promise<ErrorResponse | CancelBookingResponse> => {
  return await post('booking/cancel', body);
};

export const postDelayLookup = async (
  body: BookingDelayBody
): Promise<ErrorResponse | (TariffErrorResponse | BookingDelay)[]> => {
  return await post('tariff/delayLookup', body);
};

export const postQuote = async (
  body: BookingQuoteBody
): Promise<ErrorResponse | BookingQuoteResponse> => {
  return await post('tariff/quoteAllOptions', body);
};

export const getReportList = async (): Promise<
  ErrorResponse | ReportListResponse
> => {
  return await get('report');
};

export const getReport = async (
  body: ReportRunRequest
): Promise<ErrorResponse | ReportRunResponse> => {
  return await post('report/run', body);
};

export const getBookingFlow = async (): Promise<
  ErrorResponse | BookingFlow
> => {
  return await get('bookingFlow');
};

export const postAdflexSession = async (
  body: AdflexSessionRequest
): Promise<ErrorResponse | AdflexSessionResponse> => {
  return post('adflex/session', body);
};

export const postAddCard = async (
  body: ContactCardAddRequest
): Promise<ErrorResponse | ContactCardAddResponse> => {
  return post('contactCard/add', body);
};

export const postDeleteCard = async (
  body: ContactCardDeleteRequest
): Promise<ErrorResponse | SuccessResponse> => {
  return post('contactCard/delete', body);
};

export const postMakeDefaultCard = async (
  body: ContactCardMakeDefaultRequest
): Promise<ErrorResponse | SuccessResponse> => {
  return post('contactCard/makeDefault', body);
};

export const postAddAddress = async (
  body: ContactAddressAddRequest
): Promise<ErrorResponse | ContactAddressAddResponse> => {
  return post('contactAddress/add', body);
};

export const postEditAddress = async (
  body: ContactAddressEditRequest
): Promise<ErrorResponse | ContactAddressEditResponse> => {
  return post('contactAddress/edit', body);
};

export const postDeleteAddress = async (
  body: ContactAddressDeleteRequest
): Promise<ErrorResponse | ContactAddressDeleteResponse> => {
  return post('contactAddress/delete', body);
};

export const postJourneyMakeFavourite = async (
  body: JourneyMakeFavouriteRequest
): Promise<ErrorResponse | JourneyMakeFavouriteResponse> => {
  return post('/makeFavourite', body);
};

export const postJourneyClearFavourite = async (
  body: JourneyClearFavouriteRequest
): Promise<ErrorResponse | JourneyClearFavouriteResponse> => {
  return post('/clearFavourite', body);
};

export const postAddressMakeDefault = async (
  body: ContactAddressMakeDefaultRequest
): Promise<ErrorResponse | ContactAddressMakeDefaultResponse> => {
  return post('contactAddress/makeDefault', body);
};

export const postAddressClearDefault = async (
  body: ContactAddressClearDefaultRequest
): Promise<ErrorResponse | ContactAddressClearDefaultResponse> => {
  return post('contactAddress/clearDefault', body);
};

export const postFlightLookup = async (
  body: FlightLookupRequest
): Promise<ErrorResponse | FlightLookupResponse> => {
  return post('flightLookup', body);
};

export const postContactLinkReq = async (
  body: ContactLinkReqRequest
): Promise<ErrorResponse | ContactLinkReqResponse> => {
  return post('contactLink/request', body);
};

export const postContactLinkPendingList = async (
  body: ContactLinkListRequest
): Promise<ErrorResponse | ContactLinkPendingListResponse> => {
  return post('contactLink/pendingList', body);
};

export const postContactLinkList = async (
  body: ContactLinkListRequest
): Promise<ErrorResponse | ContactLinkListResponse> => {
  return post('contactLink/list', body);
};

export const postContactLinkAuthorise = async (
  body: ContactLinkAuthoriseRequest
): Promise<ErrorResponse | ContactLinkAuthoriseResponse> => {
  return post('contactLink/authorise', body);
};

export const postSendConfirmation = async (
  body: SendConfirmationRequest
): Promise<ErrorResponse | SendConfirmationResponse> => {
  return post('sendConfirmation', body);
};

export const postPopulate3waDetails = async (
  body: Populate3waDetailsRequest
): Promise<ErrorResponse | Populate3waDetailsResponse> => {
  return post('populate3waDetails', body);
};

export const switchToBrowser = async (): Promise<
  ErrorResponse | SwitchToBrowserResponse
> => {
  return post('switchToBrowser', {});
};

// const sendDelete = async (url: string, passCognitoJwt = true, passIntegraJwt = true): Promise<any> => {
//   const { data, headers } = baseDelete(getBaseUrls() + url, await getHeaders(passCognitoJwt, passIntegraJwt));
//   handleIntegraAuth(headers);
//   return data;
// };

const get = async (
  path: string,
  allowed404 = false,
  passCognitoJwt = true,
  passIntegraJwt = true
): Promise<any> => {
  const { data, headers } = await baseGet(
    getBaseUrls(),
    path,
    allowed404,
    await getHeaders(passCognitoJwt, passIntegraJwt)
  );
  handleIntegraAuth(headers);
  return data;
};

const post = async (
  path: string,
  body: any,
  passCognitoJwt = true,
  passIntegraJwt = true
): Promise<any> => {
  const { data, headers } = await basePost(
    getBaseUrls(),
    path,
    body,
    await getHeaders(passCognitoJwt, passIntegraJwt)
  );
  handleIntegraAuth(headers);
  return data;
};

const put = async (
  path: string,
  body: any,
  passCognitoJwt = true,
  passIntegraJwt = true
): Promise<any> => {
  const { data, headers } = await basePut(
    getBaseUrls(),
    path,
    body,
    await getHeaders(passCognitoJwt, passIntegraJwt)
  );
  handleIntegraAuth(headers);
  return data;
};

// const uploadFile = async (
//   path: string,
//   file: File,
//   fieldname: string,
//   passCognitoJwt = true,
//   passIntegraJwt = true
// ): Promise<any> => {
//   const { data, headers } = baseUploadFile(getBaseUrls(), path, file, fieldname, await getHeaders(passCognitoJwt, passIntegraJwt));
//   handleIntegraAuth(headers);
//   return data;
// };

const handleIntegraAuth = (headers: RequestHeaders) => {
  const store = useStore();
  const refreshedIntegraAuth = headers['integra-auth'];

  if (refreshedIntegraAuth && store.integraLinkJwt !== refreshedIntegraAuth) {
    logger.debug('Updated integraLinkJwt', { refreshedIntegraAuth });
    store.integraLinkJwt = refreshedIntegraAuth as string;
  }
};

const getBaseUrls = () => {
  const config = useStore().dynamicConfig;

  return config.services.integraLinkHosts;
};

const getHeaders = async (cognito = true, integra = true) => {
  let headers: any = { 'app-version': config.app.version };

  if (cognito) {
    headers = {
      ...headers,
      Authorization: `Bearer ${await getJwt()}`,
    };
  }

  if (integra) {
    const store = useStore();
    let integraLinkJwt = store.integraLinkJwt;
    if (!integraLinkJwt) {
      const user = store.integraProfile.user;
      logger.debug('Missing integraLinkJwt', { user });

      if (!user?.bemail || !user?.contactRef || !user?.accountRef) {
        await handleAutoSignOut();
        return;
      }

      await refreshIntegraSession(
        user.bemail,
        user.contactRef,
        user.accountRef
      );

      integraLinkJwt = store.integraLinkJwt;
    }
    headers = {
      ...headers,
      'integra-auth': integraLinkJwt,
    };
  }

  return headers;
};
