import config from '@/config';
import { post as basePost } from '../lowLevel';
import { LogEntry, LoggerProxyResponse } from './types';
import { encodeBase64 } from '@/utils/objectManipulation';

export const postLogEntries = async (
  logGroupName: string,
  logStreamName: string,
  logEvents: LogEntry[]
): Promise<boolean> => {
  const response = (await (post('log', {
    logGroupName,
    logStreamName,
    logEvents,
  }) as unknown)) as LoggerProxyResponse;

  return response.result === 'success';
};

const post = async (path: string, body: any): Promise<any> => {
  return basePost(
    [config.services.loggerProxy.url],
    path,
    body,
    await getHeaders()
  );
};

const getHeaders = async () => {
  const bearer = encodeBase64(
    `${config.services.loggerProxy.clientId}:${config.services.loggerProxy.clientSecret}`
  );

  return {
    Authorization: `Bearer ${bearer}`,
  };
};
