import { Capacitor } from '@capacitor/core';
import { ResourcesConfig } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import config from '../../capacitor.config';

const redirect = Capacitor.isNativePlatform()
  ? `capacitor://${config.appId}`
  : window.location.origin;

export const awsConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: process.env.VUE_APP_AUTH_IDENTITY_POOL_ID || '',

      // OPTIONAL - Amazon Cognito User Pool ID
      // (User pools > General Settings > Pool Id)
      userPoolId: process.env.VUE_APP_AUTH_USER_POOL_ID || '',

      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      // (User pools > General Settings > App clients > App client id)
      userPoolClientId: process.env.VUE_APP_AUTH_POOL_WEB_CLIENT_ID || '',

      // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
      allowGuestAccess: false,
      loginWith: JSON.parse(
        process.env.VUE_APP_AUTH_USER_POOL_IDENTITY_PROVIDERS || '[]'
      ).length
        ? {
            oauth: {
              domain: process.env.VUE_APP_AUTH_USER_POOL_DOMAIN || '',
              scopes: [
                'openid',
                'email',
                'profile',
                'aws.cognito.signin.user.admin',
              ],
              redirectSignIn: [`${redirect}/sso`],
              redirectSignOut: [`${redirect}/signin`],
              responseType: 'code', // For Authorization Code Grant
            },
          }
        : undefined,
    },
  },
};

export const getSsoUrl = (identityProvider: string) => {
  const cognitoConfig = awsConfig.Auth?.Cognito;
  const base = `https://${cognitoConfig?.loginWith?.oauth?.domain}/oauth2/authorize?`;
  const responseType = `response_type=${cognitoConfig?.loginWith?.oauth?.responseType}&`;
  const client = `client_id=${cognitoConfig?.userPoolClientId}&`;
  const state = `state=${uuidv4()}&`;
  const redirectUrl = `redirect_uri=${cognitoConfig?.loginWith?.oauth?.redirectSignIn[0]}&`;
  const scope = `scope=${cognitoConfig?.loginWith?.oauth?.scopes.join('%20')}&`;
  const provider = `identity_provider=${identityProvider}`;
  return `${base}${responseType}${client}${state}${redirectUrl}${scope}${provider}`;
};
