import { fetchAuthSession } from 'aws-amplify/auth';
import {
  CloudWatchLogsClient,
  CreateLogStreamCommand,
  CreateLogStreamCommandInput,
  CreateLogStreamCommandOutput,
  DescribeLogStreamsCommand,
  DescribeLogStreamsCommandInput,
  InputLogEvent,
  PutLogEventsCommand,
  PutLogEventsCommandInput,
  PutLogEventsCommandOutput,
} from '@aws-sdk/client-cloudwatch-logs';

import { getUsername, isSignedIn } from '../auth';
import { getModelName, getPlatform } from '../plugins/device';
import { LogEntry, getLoggerDb } from './store';
import dateFn from '@/utils/dateManipulation';
import config from '@/config';
import { createSectionLogger } from '.';
import { postLogEntries } from '@/api/loggerProxy/service';

export const logger = createSectionLogger('cloudwatch');

const LIMITS = {
  MAX_EVENT_MSG_SIZE_BYTES: 256000, // The real max size is 262144, we leave some room for overhead on each message
  MAX_BATCH_SIZE_BYTES: 1000000, // We leave some fudge factor here too.
};

// CloudWatch adds 26 bytes per log event based on their documentation:
// https://docs.aws.amazon.com/AmazonCloudWatchLogs/latest/APIReference/API_PutLogEvents.html
const BASE_EVENT_SIZE_BYTES = 26;

let batchRunning = false;

const getOrCreateLogStream = async (
  client: CloudWatchLogsClient,
  logStreamName: string
): Promise<string> => {
  try {
    const payload: DescribeLogStreamsCommandInput = {
      logGroupName: config.services.cloudWatch.logGroupName,
      logStreamNamePrefix: logStreamName,
    };
    const command = new DescribeLogStreamsCommand(payload);
    const { logStreams } = await client.send(command);

    const stream = logStreams?.find(
      (stream) => stream.logStreamName === logStreamName
    );

    if (!stream) {
      return createLogStream(client, logStreamName);
    }
  } catch (error: any) {
    logger.error('getOrCreateStream error', error);
  }

  return logStreamName;
};

const createLogStream = async (
  client: CloudWatchLogsClient,
  logStreamName: string
): Promise<string> => {
  try {
    const payload: CreateLogStreamCommandInput = {
      logGroupName: config.services.cloudWatch.logGroupName,
      logStreamName,
    };
    const command = new CreateLogStreamCommand(payload);
    const result: CreateLogStreamCommandOutput = await client.send(command);

    if (result.$metadata.httpStatusCode !== 200) {
      return '';
    }
  } catch (error: any) {
    if (error.name === 'ResourceAlreadyExistsException') {
      return logStreamName;
    } else {
      logger.error('createLogStream error', error);
    }
  }

  return logStreamName;
};

const putLogEvents = async (
  client: CloudWatchLogsClient,
  logEvents: InputLogEvent[],
  logStreamName: string
): Promise<void> => {
  const hwm = logEvents[logEvents.length - 1]?.timestamp;
  logger.debug('putLogEvents', {
    logEventsCount: logEvents.length,
    logEventsHighWatermark: hwm
      ? dateFn.formatDateTimeToIso(new Date(hwm))
      : '',
    logStreamName,
  });
  try {
    const payload: PutLogEventsCommandInput = {
      logGroupName: config.services.cloudWatch.logGroupName,
      logStreamName,
      logEvents,
    };

    const command = new PutLogEventsCommand(payload);
    const result: PutLogEventsCommandOutput = await client.send(command);

    if (result.$metadata.httpStatusCode !== 200) {
      logger.error('logger putLogEvents', result);
    }
  } catch (error: any) {
    logger.error('logger putLogEvents', error);
  }
};

export const startCloudWatchTimer = (): void => {
  let batchCount = 0;
  let hitLimit = false;
  let hitDateLimit = false;

  const timerFunction = async () => {
    if (batchRunning) {
      setTimeout(timerFunction, config.services.cloudWatch.intervalSecs * 1000);
      return;
    }

    logger.debug('CloudWatchTimer running');

    batchRunning = true;

    const batch: InputLogEvent[] = [];
    batchCount = 0;
    hitLimit = false;
    hitDateLimit = false;
    let bytes = 0;
    let batchDate: number;

    const db = getLoggerDb();
    if (!db) {
      return;
    }

    await db.logEntries
      .orderBy('timestamp')
      .limit(config.services.cloudWatch.batchSize + 1) // grab one more than the batch so we can peek and see if we have more to send
      .modify(async (logEntry: LogEntry, ref: { value: any }) => {
        if (
          !hitLimit &&
          !hitDateLimit &&
          batchCount < config.services.cloudWatch.batchSize
        ) {
          if (!batchDate) {
            batchDate =
              logEntry.timestamp - (logEntry.timestamp % 864e5) + 864e5 - 1; // get the end of the day
            logger.debug('CloudWatch set batchDate', {
              batchDate: new Date(batchDate),
            });
          } else {
            if (logEntry.timestamp > batchDate) {
              hitDateLimit = true;
            }
          }

          const ev = {
            timestamp: logEntry.timestamp,
            message: JSON.stringify(logEntry),
          };
          const evSize = ev
            ? new TextEncoder().encode(ev.message).length +
              BASE_EVENT_SIZE_BYTES
            : 0;

          if (evSize > LIMITS.MAX_EVENT_MSG_SIZE_BYTES) {
            logger.warn('CloudWatch log too large, skipping', { evSize });
          } else {
            batch.push(ev);
          }

          if (bytes + evSize > LIMITS.MAX_BATCH_SIZE_BYTES) {
            hitLimit = true;
          } else {
            bytes += evSize;
            batchCount++;

            delete ref.value;
          }
        }
      });

    if (batch.length > 0) {
      logger.debug(
        `Cloudwatch hit ${
          hitLimit
            ? 'byte'
            : hitDateLimit
            ? 'date'
            : batchCount >= config.services.cloudWatch.batchSize
            ? 'batchCount'
            : 'available'
        } limit`,
        { bytes, batchCount }
      );

      const platform = await getPlatform();
      const date = dateFn.formatLogDate(new Date());

      if (await isSignedIn()) {
        await writeToCloudWatch(platform, date, batch);
      } else {
        await writeToLogggerProxy(platform, date, batch);
      }

      batchRunning = false;

      if (
        hitLimit ||
        hitDateLimit ||
        batchCount >= config.services.cloudWatch.batchSize
      ) {
        setTimeout(timerFunction, 30 * 1000); // if more to send, wait just 30 seconds
      } else {
        setTimeout(
          timerFunction,
          config.services.cloudWatch.intervalSecs * 1000 // otherwise, use the normal timing
        );
      }
    }
  };

  setTimeout(timerFunction, 30 * 1000); // initally, try to upload after 30 seconds
  logger.info('CloudWatchTimer started');
};

const writeToCloudWatch = async (
  platform: string,
  date: string,
  batch: InputLogEvent[]
) => {
  const { credentials } = await fetchAuthSession();

  const awsLogClient = new CloudWatchLogsClient({
    region: config.services.cloudWatch.region,
    credentials,
  });

  const cognitoId = await getUsername();
  const logStreamName = await getOrCreateLogStream(
    awsLogClient,
    `${date}-${platform}-${cognitoId}`
  );
  if (!logStreamName) {
    logger.info('No logStream available');
    return;
  }

  await putLogEvents(awsLogClient, batch, logStreamName);
};

const writeToLogggerProxy = async (
  platform: string,
  date: string,
  batch: InputLogEvent[]
) => {
  const logGroupName = config.services.cloudWatch.logGroupName;
  const modelName = await getModelName();

  return await postLogEntries(
    logGroupName,
    `${date}-${platform}-${modelName}`,
    batch
  );
};
