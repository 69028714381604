import { LogEvent, LoggerHook } from 'vue-logger-plugin';
import Dexie, { Table } from 'dexie';

import dateFn from '@/utils/dateManipulation';

export type LogEntry = {
  id?: number;
  timestamp: number;
  level: string;
  message: string;
  data: Record<string, any>;
};

export class LogEntryDb extends Dexie {
  // 'logEntries' is added by dexie when declaring the stores()
  // We just tell the typing system this is the case
  logEntries!: Table<LogEntry>;

  constructor() {
    super('logEntryDb');
    this.version(1).stores({
      logEntries: '++id, timestamp, level', // Primary key and indexed props
    });
  }
}

const loggerDb = new LogEntryDb();

export const getLoggerDb = (): LogEntryDb => {
  return loggerDb;
};

export const setupLoggerDb = async (): Promise<void> => {
  deleteOldRecords();
};

export const deleteOldRecords = (keepDays = 3) => {
  const db = getLoggerDb();
  if (!db) {
    return;
  }

  const daysAgo = dateFn.nowSubtract({ days: keepDays });

  db.logEntries
    .where('timestamp')
    .below(daysAgo)
    .delete()
    .catch((err) => console.error(err));
};

export const DbLoggerHook: LoggerHook = {
  run(event: LogEvent) {
    const db = getLoggerDb();
    if (!db) {
      return;
    }

    db.logEntries
      .add({
        timestamp: Date.now(),
        level: event.level,
        message: event.argumentArray[0],
        data: event.argumentArray.slice(1),
      })
      .catch((err) => console.error(err));
  },
};

export const getLoggerDbStatus = (): boolean => {
  return loggerDb ? true : false;
};

export const getRecentLogs = async (): Promise<LogEntry[] | void> => {
  const db = getLoggerDb();
  if (!db) {
    return;
  }

  return await db.logEntries
    .orderBy('timestamp')
    .limit(1000)
    .toArray()
    .catch((err) => console.error(err));
};
