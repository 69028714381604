import { KeyValueStorageInterface } from 'aws-amplify/utils';
import localforage from 'localforage';

export class AuthStorage implements KeyValueStorageInterface {
  syncPromise: Promise<void> | undefined;
  memoryStore: Record<string, any> = {};
  asyncStore = localforage.createInstance({
    name: 'amplifyAuthStore',
  });

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  setItem(key: string, value: any): any {
    this.asyncStore.setItem(key, value);
    this.memoryStore[key] = value;
    return this.memoryStore[key];
  }

  getItem(key: string): any {
    return Object.prototype.hasOwnProperty.call(this.memoryStore, key)
      ? this.memoryStore[key]
      : undefined;
  }

  removeItem(key: string): any {
    this.asyncStore.removeItem(key);
    return delete this.memoryStore[key];
  }

  clear(): any {
    this.asyncStore.clear();
    return {};
  }

  sync(): Promise<void> {
    if (!this.syncPromise) {
      this.syncPromise = this.asyncStore.iterate((val: string, key) => {
        this.memoryStore[key] = val;
      });
    }
    return this.syncPromise;
  }
}
