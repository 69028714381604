import {
  createLogger,
  LogLevel,
  StringifyAndParseObjectsHook,
} from 'vue-logger-plugin';

import config from '@/config';
import { DbLoggerHook } from './store';

export { createSectionLogger } from './sectionLogger';

// Use before hooks to format output
// Use after hooks to e.g. send to an API like Google Logs or Sentry

const logger = createLogger({
  enabled: true,
  consoleEnabled: true,
  level: config.app.logLevel as LogLevel,
  beforeHooks: [StringifyAndParseObjectsHook],
  afterHooks: [DbLoggerHook],
});

export const updateLogLevel = (level: string) => {
  logger.apply({ level: level as LogLevel });
};

export default logger;
